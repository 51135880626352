<template>
  <section v-if="enabledMembersApp">
    <widget>
      <template #header>
        <div class="d-flex my-1 mb-2 w-100 justify-content-between">
          <div class="d-flex align-items-center">
            <a class="mr-50" @click="$router.go(-1)">
              <feather-icon icon="ChevronLeftIcon" size="20" />
            </a>
            <h3 class="m-0 font-weight-600">
              {{ appTitle || $t("members.title") }}
            </h3>
          </div>

          <!-- Panel actions -->
          <widget-actions
            item-type="members"
            :show-filter="showfilter && !noShowMembers"
            :show-list="true"
            :show-add="false"
            :size="members.length"
            :show-maximize="false"
            @toggleFilters="toggleFilters"
            @updateSearch="applySearch"
          />
        </div>
      </template>

      <!-- FILTERS -->
      <b-row class="media m-0">
        <b-col v-if="showFiltersCol" cols="12" lg="2" class="media__filters pl-0">

          <!-- FILTERS COLUMN -->
          <b-card class="addon">
              <!--  -->
              <div class="mx-50 d-flex justify-content-between align-items-center">
                  <h3>
                  {{ $t("media.filters.action.filter") }}
                  </h3>
                  <b-icon-filter
                      v-if="classifiersTree.length > 0"
                      variant="primary"
                      class="d-lg-none"
                      @click="toggleFilters"
                  />
              </div>

              <!-- Classifiers -->
              <div
                  v-if="classifiersTree.length > 0"
                  class="flex-column justify-content-center mb-2 px-50 pb-1 pt-1"
                  :class="areFiltersVisible ? 'd-flex' : 'd-none d-lg-flex'"
              >

                  <!-- Filters Selection -->
                  <b-form-group
                      v-for="classifierType in classifiersTree"
                      :key="classifierType.key"
                      :label="translateTranslationTable(currentLocale, classifierType.name)"
                      :label-for="`classifier-filter-${classifierType.key}`"
                      class="w-100"
                  >
                      <v-select
                          v-model="selectedClassifiersForFilter[classifierType.key]"
                          :name="`classifier-filter-${classifierType.key}`"
                          clearable
                          :searchable=true
                          label="name"
                          placeholder="Select..."
                          :reduce="(a) => a"
                          return-object
                          :options="filterOptions(classifierType)"
                          @input="setSelectedFinalFilter"
                      />
                  </b-form-group>

                  <!-- Filter Logic -->
                  <b-form-group
                      :label="$t('filters.logic')"
                      label-for="filterLogicLabel"
                      class="w-100"
                  >
                      <v-select
                        name="filterLogicLabel"
                        v-model="filterLogic"
                        :searchable=false
                        :clearable="false"
                        label="name"
                        :reduce="(a) => a"
                        return-object
                        placeholder="Select..."
                        :options="orAndOptions"
                      />
                  </b-form-group>

                  <!-- Submit to filter -->
                  <b-button
                      variant="primary"
                      type="submit"
                      @click.prevent="applyFilter()"
                  >
                      APPLY FILTER
                  </b-button>
              </div>

              <!-- No filters available -->
              <b-row
                  v-else
                  class="horizontal-placeholder d-none d-lg-block h-100 media-placeholder"
              >
                  <b-col cols="12">
                  <img :src="placeholderFilter" />
                  </b-col>
                  <b-col cols="12">
                  <p class="text-primary">
                      {{ $t("available.message", { itemName: $t("media.filters.action.filter") }) }}
                  </p>
                  </b-col>
              </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" :lg="showFiltersCol ? 10 : 12">
          <!-- Members table -->
          <members-list
            :per-page="perPage"
            enable-card-size="xl"
            :members="members"
            :is-loading="isLoading"
            :total="total || 0"
            class="members-table"
            :classifiers="classifiers"
            :no-show-members="noShowMembers"
            :filter="filtersForTable"
            :is-filtering="isFiltering"
            :is-searching="isSearching"
            @total-updated="setAddOnSize($event)"
            @handleLoadOfNewItems="handleChanger"
          />
        </b-col>
      </b-row>

    </widget>
  </section>
  <section v-else>
    <uninstalled-addon
      addonKey="members" 
    />
  </section>
</template>

<script>
import Widget from "@core/widgets/Widget.vue";
import MembersList from "@/views/apps/member/components/MembersList.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import OrderBy from "@core/constants/OrderBy";
import vSelect from "vue-select";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import UninstalledAddon from '@/@core/components/UninstalledApps/UninstalledAddon.vue'

export default {
  components: {
    MembersList,
    Widget,
    WidgetActions,
    vSelect,
    UninstalledAddon
  },
  mixins: [ToastNotificationsMixin],
  props: {
    disableViewMore: Boolean,
    organizationKey: { type: String, default: null },
  },

  data() {
    return {
      perPage: 20,
      isLoading: false,
      page: 1,
      //Filters
      showFiltersCol: false,
      filterLogic: { name: this.$t("AND"), value: "AND" },
      areFiltersVisible: false,
      filtersIndex: 0,
      filters: [],
      parentClassifiers: [],
      nextFilter: [],
      selectedClassifiersForFilter: [],
      selectedCustomFieldsForFilter: [],
      customFieldsInputs: [],
      customFieldToSend: {},
      index: 0,
      timer: null,
      searchString: "",
      finalSelectClassifier: [],
      currentPage: 1,
      showfilter: false,
      isSomeFilterChanged: false,
      isSearching: false,
      isFiltering: false,
      selectedFilteringClassifiers: []
    };
  },

  computed: {
    filter() {
      return (
        (this.selectedCustomFieldsForFilter &&
          this.selectedCustomFieldsForFilter.length > 0)
      );
    },
    noShowMembers() {
      return (
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].members
          ?.customization?.organizationView === "none"
      );
    },
    filtersForTable() {
      return 
        (
          this.selectedCustomFieldsForFilter 
          && this.selectedCustomFieldsForFilter.length > 0 
        ) || (
          this.selectedCustomFieldsForFilter 
          && this.selectedCustomFieldsForFilter.length > 0
        );
    },
    placeholderFilter() {
            return FilterPlaceholder;
    },
    orAndOptions() {
        //return ["OR", "AND"];
        return [
            { name: this.$t("AND"), value: "AND" },
            { name: this.$t("OR"), value: "OR" }
        ];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName
      );
    },
    members() {
      // if (this.$store.getters.organizationMembers[this.organizationKey]) {
      //   return this.$store.getters.organizationMembers[this.organizationKey]
      //     .unpaginated;
      // }

      // console.log('this.$store.getters.communityMembers', this.$store.getters.communityMembers);
      if (
        this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ]
      ) {
        return this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ].unpaginated;
      }  
      
      return [];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    total() {
      if (this.$store.getters.organizationMembers[this.organizationKey]) {
        return this.$store.getters.organizationMembers[this.organizationKey]
          .meta.total;
      }
      if (
        this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ]
      ) {
        return this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ].meta.total;
      }
      return 0;
    },
    // Filters
    classifiersTree() {
      if (this.$store.getters.classifiersTree.members) {
        return this.$store.getters.classifiersTree.members.unpaginated;
      }
      return [];
    },
    classifiers() {
      if (this.$store.getters.classifiers.members) {
        return this.$store.getters.classifiers.members.unpaginated;
      }
      return [];
    },
    customFields() {
      return this.$store.getters.customFields ?? [];
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      }
      const keys = Object.keys(newObject);
      const finalKeys = [];
      for (const row of keys) {
        finalKeys.push({
          key: row,
          name: row,
          type: 1,
        });
      }
      return [newObject, finalKeys];
    },
    finalOptions() {
      return [].concat(this.sortedCommunityClassifiers[1]);
    },
    app() {
      return Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).find(({ id }) => id === 50);
    },
    apps(){
      return this.$store.getters.apps?.apps
    },
    enabledApps() {
      const installedApps=[];
      Object.values(this.apps).forEach(item => {installedApps.push(item.nameSingular)})
      return installedApps;
    },
    enabledMembersApp() {
      return this.enabledApps?.filter((item) => item === 'member').length > 0;
    },
  },
  async created() {
    //console.log('members:', this.$store.getters.communityMembers[this.$store.getters.currentCollective.slug])

    if(this.members.length <= 0){
      // console.log('No members found...');
      await this.fetchData({
        page: this.currentPage,
        searchString: "",
        forceAPICall: true,
      });
    }
    this.canFilter();
    if(this.classifiersTree.length <= 0){
      // await this.fetchClassifiers();
      await this.fetchClassifiersTree(true);
      // console.log('this.classifiersTree', this.classifiersTree);
      if(this.classifiersTree?.length > 0){
        this.showFiltersCol = true;
      }
    }
  },

  methods: {
    async fetchData({ page, searchString, forceAPICall = false, classifiers = [], customFields = [], filterLogic = 'OR' }) {
      // console.log('Getting members...');
      this.isLoading = true;

      //set config
      const requestConfig = {
        orderByName: OrderBy.ASC,
        organizationKey: this.organizationKey ? this.organizationKey : null,
        //SEARCHING
        searchString: searchString && searchString !== '' ? searchString : null,
        //FILTERING
        classifiersOLD: this.selectedCustomFieldsForFilter?.length > 0 ? this.selectedCustomFieldsForFilter : null,
        classifiers: this.selectedFilteringClassifiers,
        customFields:
          this.customFieldToSend != null &&
          Object.keys(this.customFieldToSend).length > 0
            ? this.customFieldToSend
            : null,
        filterLogic: this.filterLogic?.value
      };
      // console.log('requestConfig', requestConfig);

      // call backend
      await this.$store.dispatch("getItems", {
        itemType: this.organizationKey
          ? "organizationMembers"
          : "communityMembers",
        storedKey: this.organizationKey
          ? this.organizationKey
          : this.$store.getters.currentCollective.slug,
        page,
        forceAPICall,
        perPage: this.perPage,
        requestConfig: requestConfig,
      });

      // end loading
      this.isLoading = false;
    },
    async fetchClassifiers() {
      const response = await this.$store.dispatch("getItems", {
        itemType: "backoffice/classifiers",
        customName: 'classifiers',
        storedKey: "members",
        page: 1,
        requestConfig: { 
          modelType: "member", 
          count: 1000 
        },
      });
      this.isLoading = false;
    },
    async fetchClassifiersTree(force = false) {
      // console.log('fetching classifiersTree');
      await this.$store.dispatch("getItems", {
        itemType: "classifiersTree",
        storedKey: 'members',
        forceAPICall: force,
        requestConfig: {
          modelType: 'member',
          morphType: 'member',
          count: 1000,
        },
      });
    },
    filterOptions(classifierType) {
        let translatedClassifiers = [];
        for(let classifier of classifierType.classifiers){
            //console.log('classifier', classifier);
            translatedClassifiers.push({
                // text: this.translateTranslationTable(this.currentLocale, classifier.name),
                // value: classifier.key,
                name: this.translateTranslationTable(this.currentLocale, classifier.name),
                typeKey: classifierType.key,
                key: classifier.key,
                type: 1,
            });
        }
        return translatedClassifiers;
    },
    canFilter() {
      // console.log('this.classifiers', this.classifiers)
      if (this.classifiers.length > 0) {
        this.showfilter = true;
      } else {
        this.showfilter = false;
      }
      // console.log('this.showfilter', this.showfilter);
    },
    // handleChanger(search, page = { page: 1 }) {
    //   this.isSearching = search?.length > 0;
    //   this.currentPage = page.page;
    //   if (this.timer) {
    //     clearTimeout(this.timer);
    //     this.timer = null;
    //   }
    //   this.timer = setTimeout(() => {
    //     this.fetchData({
    //       page: this.currentPage,
    //       searchString: search,
    //       searchCountry: this.selectedCountry ?? [],
    //       forceAPICall: true,
    //     });
    //   }, 800);
    // },
    handleChanger({ page = 1, search = '' }) {
      this.isSearching = search.length > 0;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchData({
          page,
          searchString: search,
          searchCountry: this.selectedCountry ?? [],
          forceAPICall: true,
          // classifiers: this.selectedFilteringClassifiers,
          // customFields:
          //   this.customFieldToSend != null &&
          //   Object.keys(this.customFieldToSend).length > 0
          //     ? this.customFieldToSend
          //     : null,
          // filterLogic: this.filterLogic?.value
        });
      }, 800);
    },
    applyFilter() {
      this.isFiltering = true;
      this.handleChanger({ page: 1, search: '' });
    },
    applySearch(text) {
      this.handleChanger({ page: 1, search: text })
    },
    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
      if (!this.areFiltersVisible) {
        this.customFieldToSend = {};
        this.finalSelectClassifier = [];
        this.nextFilter = null;
        this.selectedCustomFieldsForFilter = null;
        this.filtersIndex = 0;
        this.filters = [];
        this.parentClassifiers = [];
        this.selectedClassifiersForFilter = [];
        this.customFieldsInputs = [];
        this.index = 0;
        if (this.isSomeFilterChanged) {
          this.fetchData({
            page: 1,
            searchString: this.searchString,
            forceAPICall: true,
          });
        }
      }
    },
    async setSelectedFilter(selected) {
      this.CustomFieldToSend = {};
      this.finalSelectClassifier = null;
      this.selectedCustomFieldsForFilter = null;
      this.nextFilter = null;
      if (!selected || selected.lenght === 0) {
        this.finalSelectClassifier = null;
      } else {
        let translated = [];
        if (selected.type === 1) {
          this.selectedClassifiersForFilter = selected.key;
          for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
            translated.push({
              name: translateTranslationTable(
                this.$store.state.locale.currentLocale,
                row.name
              ),
              key: row.key,
              type: 1,
            });
          }
        } else if (selected.type === 2) {
          this.customFieldToSend[selected.key] = "";
          translated = { type: 2, key: selected.key };
        }
        this.nextFilter = translated.reduce((acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        }, []);
      }
    },
    // async setSelectedFinalFilter(selected) {
    //   if (selected) {
    //     if (selected.type === 2) {
    //       this.customFieldToSend[selected.key] = selected.text;
    //     } else {
    //       this.finalSelectClassifier = selected.key;
    //     }
    //     this.isSomeFilterChanged = true;
    //   } else {
    //     this.finalSelectClassifier = null;
    //   }
    //     await this.handleChanger({ page: 1, search: '' });
    // },
    async setSelectedFinalFilter(selected) {
      // console.log('setSelectedFinalFilter selected', selected);
      // console.log('this.selectedClassifiersForFilter', this.selectedClassifiersForFilter);
      this.selectedFilteringClassifiers = [];
      for(let selectedClassifier of Object.values(this.selectedClassifiersForFilter)){
        // console.log('selectedClassifier', selectedClassifier);
        if(selectedClassifier?.key){
          this.selectedFilteringClassifiers.push(selectedClassifier.key);
        }
      }
      // console.log('this.selectedFilteringClassifiers', this.selectedFilteringClassifiers);
    },
    async handleLoadOfNewItems({ page }) {
      if (!this.isLoading && this.members.length <= this.total) {
        await this.fetchData({
          page,
          searchString: this.searchString,
          forceAPICall: true,
        });
      }
    },
    // searchItems(value) {
    //   this.searchString = value;
    //   if (value == "") {
    //     return this.fetchData({
    //       page: 1,
    //       searchString: "",
    //       forceAPICall: true,
    //     });
    //   }
    //   const searchTerm = value.toLowerCase().trim();
    //   this.fetchData({ page: 1, searchString: searchTerm, forceAPICall: true });
    // },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t("members.title"),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    translateTranslationTable,
  },
};
</script>

<style lang="scss" scoped>
.members-table {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex: 1;
  padding-bottom: 20px;
}
</style>
